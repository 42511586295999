












import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { BlogFilter } from 'client-website-ts-library/filters';
import { BlogType, WebsiteLevel } from 'client-website-ts-library/types';
import Blogs from '../components/Blogs.vue';

@Component({
  components: {
    Blogs,
  },
})
export default class BlogPromo extends Mixins(View) {
  private filter = new BlogFilter({
    SearchLevel: WebsiteLevel.Profile,
    SearchGuid: 'ee64d590-e007-4e19-9886-495c7ce7d35b',
    Types: [BlogType.Promotions],
  });
}
